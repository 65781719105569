<template>
  <div class="delete-modal flex items-center justify-center">
    <div class="delete-modal--inner p-20" v-click-outside="onCancel" style="background-color:#F8F8F8">
      <div class="flex flex-col mt-2">
        <h2 class="text-3xl font-bold mb-10">Edit modal</h2>
        <div class="mb-5 form-control">
            <label>Name:</label>
            <input type="text" v-model="editedAgent.name" autocomplete="off">
        </div>
        <div class="form-control mb-5">
            <label>E-Mail:</label>
            <input type="text" v-model="editedAgent.email" readonly @focus="removeReadonly('email')" ref="email" autocomplete="off">
        </div>
        <div class="form-control mb-5">
            <label>Tag:</label>
            <input type="text" v-model="editedAgent.tag" autocomplete="off">
        </div>
        <div class="form-control mb-5">
            <label>Passwort:</label>
            <input type="password" v-model="editedAgent.password" readonly @focus="removeReadonly('password')" ref="passwordInput" autocomplete="off">
            <i class="fas fa-eye" @click="togglePassword"></i>
        </div>
        <div class="self-start">
            <button class="bg-blue-600 text-white text-2xl font-bold mr-5 py-4 px-8" @click="onEdit">Aktualisieren</button>
            <button class="bg-gray-600 text-white text-2xl font-bold py-4 px-8" @click="onCancel">Stornieren</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .delete-modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(#000, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 !important;
    z-index: 50;
    .close {
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      border: 15px solid rgb(220, 38, 38);
      width: 15rem;
      height: 15rem;
      display: grid;
      place-items: center;
    }
    &--inner {
      height: min-content;
    }
  }
</style>

<script>
  import Vue from 'vue';

  export default {
    name: 'EditModal',
    data() {
      return {
        editedAgent: Vue.util.extend({}, {
          name: this.agent.name,
          email: this.agent.email,
          password: '',
          tag: this.agent.tag
        })
      }
    },
    props: ['agent'],
    methods: {
        onEdit() {
            this.$emit('onEdit', this.editedAgent)
        },
        onCancel() {
            this.$emit('onCancel')
        },
        togglePassword() {
            if(this.$refs.passwordInput.type == 'password') {
                this.$refs.passwordInput.type = 'text';
            }
            else {
                this.$refs.passwordInput.type = 'password';
            }
        },
        removeReadonly(type) {
            switch(type) {
                case 'email': {
                    this.$refs.email.removeAttribute("readonly");
                    this.$refs.email.blur();
                    this.$refs.email.focus();
                    break;
                }
                case 'password': {
                    this.$refs.passwordInput.removeAttribute("readonly");
                    this.$refs.passwordInput.blur();
                    this.$refs.passwordInput.focus();
                    break;
                }
            }
        }
    }
  };
</script>
