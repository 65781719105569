<template>
  <div class="users shadow p-16" style="background-color:#F8F8F8">
    <DeleteModal message="Wollen Sie diesen Agenten wirklich löschen?" v-if="deleteShownModal" @onCancel="closeDeleteModal" @onDelete="onDelete"/>
    <EditModal v-if="editShownModal" :agent="editedAgent" @onCancel="closeEditModal" @onEdit="onEdit"/>
    <p class="text-3xl mb-10">Hier können Sie die Liste aller Benutzer einsehen</p>
    <div class="table-users my-6" style="background-color:#F8F8F8">
      <table class="min-w-max w-full table-auto">
        <thead>
          <tr
            class="text-white uppercase text-2xl leading-normal"
          >
            <th class="py-5 px-10 text-left">Vornamen</th>
            <th class="py-5 px-10 text-left">E-Mail</th>
            <th class="py-5 px-10 text-left">Tag</th>
            <th class="py-5 px-10 text-left">({{ ticketCount }}) Tickets heute</th>
            <th class="py-5 px-10 text-left">Aktionen</th>
          </tr>
        </thead>
        <tbody class="text-gray-600 text-sm font-light">
          <tr v-for="agent in agents" :key="agent._id" class="border-b border-gray-200 hover:bg-gray-100">
            <td class="py-5 px-10 text-left whitespace-nowrap">
                <span class="font-medium text-2xl">{{ agent.name }}</span>
            </td>
            <td class="py-5 px-10 text-left">
                <span class="text-2xl">{{ agent.email }}</span>
            </td>
            <td class="py-5 px-10 text-left">
                <span class="text-2xl">{{ agent.tag }}</span>
            </td>
            <td class="py-5 px-10 text-left">
                <span class="text-2xl">{{ agent.ticketCount }}</span>
            </td>
            <td class="py-5 px-10 text-left">
                <button class="button py-3 px-5 bg-red-600 text-white text-2xl hover:bg-red-400 mr-3" @click.stop="deleteAgent(agent._id)"><i class="fas fa-times"></i></button>
                <button class="button py-3 px-5 bg-yellow-400 text-white text-2xl hover:bg-yellow-200" @click.stop="editAgent(agent)"><i class="fas fa-pencil-alt"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  thead {
    tr {
      background: #4F98C1;
    }
  }
  @media screen and (max-width: 576px) {  
    .table-users {
      overflow-x: auto;
    }
  }
</style>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import DeleteModal from '@/components/DeleteModal.vue';
  import EditModal from '@/components/EditModal.vue';

  export default {
    data() {
      return {
        deleteShownModal: false,
        editShownModal: false,
        deletion_id: '',
        editedAgent: {}
      }
    },
    mounted() {
      this.getAgents();
    },
    methods: {
      ...mapActions(['getAgents', 'removeAgent', 'updateAgent']),
      deleteAgent(id) {
        this.deleteShownModal = true;
        this.deletion_id = id;
      },
      editAgent(agent) {
        this.editShownModal = true;
        this.editedAgent = agent;
      },
      onDelete() {
        this.removeAgent(this.deletion_id);
        this.deletion_id = '';
        this.deleteShownModal = false;
      },
      onEdit(agent) {
        if(agent.email != this.editedAgent.email ||
           agent.name != this.editedAgent.name ||
           agent.tag != this.editedAgent.tag ||
           agent.password.length) {
             this.updateAgent({
               id: this.editedAgent._id,
               agent: agent 
             });
           }
        this.closeEditModal();
      },
      closeDeleteModal() {
        this.deleteShownModal = false;
      },
      closeEditModal() {
        this.editShownModal = false;
      }
    },
    computed: {
      ...mapGetters(['agents']),
      ticketCount() {
        return this.agents.reduce((a, b) => {
          if(b.ticketCount) {
            return a + b.ticketCount;
          } else {
            return a + 0;
          }
        }, 0);
      }
    },
    components: {
      DeleteModal,
      EditModal
    }
  }
</script>
