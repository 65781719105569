<template>
    <div class="register p-16 shadow" style="background-color:#F8F8F8">
        <p class="text-3xl mb-10 leading-normal">Mit diesem Formular können Sie neue Agenten auf der Plattform hinzufügen!</p>
        <form @submit.prevent="submitForm">
            <div class="flex">    
                <div class="flex-1 form-control pr-5">
                    <label>Vornamen:</label>
                    <input type="text" v-model="agent.first_name" autocomplete="off">
                </div>
                <div class="flex-1 form-control pl-5">
                    <label>Nachname:</label>
                    <input type="text" v-model="agent.last_name" autocomplete="off">
                </div>
            </div>
            <div class="form-control">
                <label>E-Mail:</label>
                <input type="text" v-model="agent.email" @focus="removeReadonly('email')" ref="email" autocomplete="off">
            </div>
            <div class="form-control">
                <label>Tag:</label>
                <input type="text" v-model="agent.tag" autocomplete="off">
            </div>
            <div class="qa">
            <label for="qa">QA:</label>
            <input type="checkbox" id="qa" v-model="agent.qa" autocomplete="off">
            </div>
            <div class="form-control">
                <label>Passwort:</label>
                <input type="password" v-model="agent.password" @focus="removeReadonly('password')" ref="passwordInput" autocomplete="off">
                <i class="fas fa-eye" @click="togglePassword"></i>
            </div>
            <button type="submit" class="primary-btn">Registrieren</button>
        </form>
    </div>
</template>

<style lang="scss" scoped>
    .qa {
        label {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 15px;
            display: inline-block;
        }
        input[type=checkbox] {
            transform: scale(2);
            margin-left: 20px;
        }
    }
</style>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            agent: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                tag: '',
                qa: false
            }
        }
    },
    mounted() {
        this.$refs.email.setAttribute("readonly", "true");
        this.$refs.passwordInput.setAttribute("readonly", "true");
    },
    methods: {
        ...mapActions(['addAgent']),
        togglePassword() {
            if(this.$refs.passwordInput.type == 'password') {
                this.$refs.passwordInput.type = 'text';
            }
            else {
                this.$refs.passwordInput.type = 'password';
            }
        },
        submitForm() {
            const agent = {
                name: this.agent.first_name + " " + this.agent.last_name,
                email: this.agent.email,
                password: this.agent.password,
                tag: this.agent.tag,
                qa: this.agent.qa
            }
            this.addAgent(agent);
            this.agent = {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                tag: '',
                qa: false
            }
        },
        removeReadonly(type) {
            switch(type) {
                case 'email': {
                    this.$refs.email.removeAttribute("readonly");
                    this.$refs.email.blur();
                    this.$refs.email.focus();
                    break;
                }
                case 'password': {
                    this.$refs.passwordInput.removeAttribute("readonly");
                    this.$refs.passwordInput.blur();
                    this.$refs.passwordInput.focus();
                    break;
                }
            }
        }
    }
}
</script>

