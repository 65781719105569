<template>
  <div class="delete-modal flex items-center justify-center">
    <div class="delete-modal--inner p-20" v-click-outside="onCancel" style="background-color:#F8F8F8">
      <div class="close text-center mb-10">
          <i class="fas fa-times text-9xl text-red-600"></i>
      </div>
      <h2 class="font-bold text-5xl text-center text-gray-700">Sind Sie sicher?</h2>
      <p class="text-3xl mt-10 leading-normal text-center max-w-2xl">{{ message }} Dieser Vorgang kann nicht rückgängig gemacht werden.</p>
      <div class="flex justify-center mt-10">
        <button class="bg-red-600 text-white text-2xl font-bold mr-5 py-4 px-8" @click="onDelete">Löschen</button>
        <button class="bg-blue-600 text-white text-2xl font-bold py-4 px-8" @click="onCancel">Stornieren</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .delete-modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(#000, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 !important;
    z-index: 50;
    .close {
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      border: 15px solid rgb(220, 38, 38);
      width: 15rem;
      height: 15rem;
      display: grid;
      place-items: center;
    }
    &--inner {
      height: min-content;
    }
  }
</style>

<script>
  export default {
    name: 'DeletModal',
    props: ['message'],
    methods: {
      onDelete() {
        this.$emit('onDelete')
      },
      onCancel() {
        this.$emit('onCancel')
      }
    }
  };
</script>
