<template>
    <div class="sidebar w-full">
        <h3 class="text-2xl font-bold mb-5">Suche</h3>
        <div class="form-control">
            <input type="text" autocomplete="off" :value="filter" ref="search" @input="updateValue">
            <i class="fas fa-search" style="top: 1.3rem"></i>
        </div>
        <h3 class="text-2xl font-bold mb-5 mt-14">
            <select v-model="selected" class="bg-gray-50">
                <option :value="'gelost'" >Kürzlich gelöste Tickets</option>
                <option :value="'fav'">Favourites</option>
            </select>
            <router-link v-if="selected === 'gelost'" to="/tickets/solved" class="text-blue-500 text-xl font-normal ml-5 inline-block">Alle anzeigen</router-link>
        </h3>
        <div class="text-2xl font-normal mb-5 mt-14" v-if="!ticketTitles.length && selected === 'gelost'">
            Es sind keine kürzlich gelösten Tickets vorhanden
        </div>
        <div class="tickets" v-if="ticketTitles.length && selected === 'gelost'">
            <router-link :to="`/tickets/${ticket.id}`" class="block ticket hover:bg-blue-100 cursor-pointer shadow px-6 py-10 mb-5 bg-gray-50" v-for="ticket in ticketTitles" :key="ticket._id">
                <p class="text-2xl font-bold">{{ ticket.subject }}</p>
                <div class="flex justify-between">
                    <p class="text-2xl mt-3">{{ ticket.customer }}</p>
                    <p class="text-2xl mt-3 text-gray-400" style="max-width: 40%">{{ ticket.created }}</p>
                </div>
            </router-link>
        </div>
        <div class="tickets" v-else-if="selected === 'fav'">
            <button class="bg-blue-500 p-2 mt-2 mb-2 text-2xl text-white" @click="clearFavorites">Clear All Favourites</button>
            <router-link :to="`/tickets/${ticket.ticketId}`" class="block ticket hover:bg-blue-100 cursor-pointer bg-white shadow px-6 py-10 mb-5" v-for="(ticket) in favedTickets" :key="ticket.ticketId">
                <div class="flex justify-between">
                    <div>
                        <p class="text-2xl font-bold">{{ ticket.ticketSubject }}</p>
                        <p class="text-2xl mt-3">{{ ticket.ticketCustomer }}</p>
                        <p class="text-2xl mt-3 text-gray-400" style="max-width: 40%">{{ ticket.created }}</p>
                    </div>
                    <div class="icon-star ml-4" @click.stop="removeFromFavorites(ticket.ticketId)" @click.prevent="active = true">
                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star filled">
                            <polygon points="12 2 15 8 21 9 17 14 18 21 12 18 6 21 7 14 3 9 9 8 12 2"></polygon>
                        </svg>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .icon-star svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
        // transition: fill 0.3s ease;
    }
    .icon-star svg.filled {
        fill: black;
        stroke: black;
    }
    @media screen and (max-width: 576px) {
        .sidebar {
            max-width: 100%;
            .tickets {
                display: none;
            }
        }  
    }
    
</style>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            filter: '',
            selected: 'gelost',
            favedTickets: [],
            isStarred: false
        }
    },
    watch: {
        selected(newValue) {
            if (newValue === 'fav') {
                this.loadFavedTickets();
            }
        }
    },
    mounted() {
        this.getRecently(this.loggedUser.tag);
        this.$root.$on('favourite-updated', this.updateFavedTickets);
        this.$root.$on('favorites-cleared', this.clearFavorites);
        this.$root.$on('favourites-sidebar-updated', this.removeFromFavorites)
    },
    beforeDestroy() {
        this.$root.$off('favourite-updated', this.updateFavedTickets);
        this.$root.$off('favorites-cleared', this.clearFavorites); 
    },
    methods: {
        ...mapActions(['getRecently', 'fetchTicketById', 'getTicket']),
        updateValue() {
            this.filter = this.$refs.search.value;
            this.$emit('input', this.filter);
        },
        async loadFavedTickets() {
            try {
                const favedTicketData = localStorage.getItem('favouritedTickets');
                if (!favedTicketData) {
                    this.favedTickets = [];
                    return;
                }
                const parsedFavedTicketData = JSON.parse(favedTicketData);
                
                if (!Array.isArray(parsedFavedTicketData)) {
                    throw new TypeError('favedTicketData is not an array');
                }
                this.favedTickets = parsedFavedTicketData
            } catch (err) {
                console.error('Failed to load faved tickets', err);
            }
        },
        async updateFavedTickets({ ticket, isStarred }) {
            if (isStarred) {
                const ticketData = {
                    ticketId: ticket.ticketId,
                    ticketCustomer: ticket.ticketCustomer,
                    ticketSubject: ticket.ticketSubject,
                    ticketStarred: ticket.ticketStarred,
                    created: ticket.created
                };
                if (!this.favedTickets.some(t => t.ticketId === ticket.ticketId)) {
                    this.favedTickets.push(ticketData);
                }
            } else {
                this.favedTickets = this.favedTickets.filter(t => t.ticketId !== ticket.ticketId);
            }
            localStorage.setItem('favouritedTickets', JSON.stringify(this.favedTickets));
        },
        clearFavorites(){
            if (!this.isClearing) {
                this.isClearing = true;
                window.localStorage.removeItem('favouritedTickets')
                this.favedTickets = []
                this.$root.$emit('favorites-cleared');
                this.isClearing = false;
            }
        },
        removeFromFavorites(ticketId) {
            this.favedTickets = this.favedTickets.filter(t => t.ticketId !== ticketId);
            localStorage.setItem('favouritedTickets', JSON.stringify(this.favedTickets));
            this.$root.$emit('favourite-sidebar-updated');
        },
        toggleStar() {
            this.isStarred = !this.isStarred;
        }
    },
    computed: {
        ...mapGetters(['recentlySolved', 'loggedUser']),
        ticketTitles() {
            return this.recentlySolved.map(el => {
                if(el.subject.length > 60) {
                    return {...el, subject: el.subject.substr(0, 60) + "..."};
                } else {
                    return el;
                }
            })
            
        }
    }
}
</script>